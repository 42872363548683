import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useGetAllSubscriptions } from '../../hooks/mutations'

const PaymentPortal = () => {
  const subscriptions = useGetAllSubscriptions();
  console.log('subscriptions', subscriptions)
  return (
    <Grid
      container
    >
      <Typography>Manage your subscription from your customer portal below.</Typography>
    </Grid>
  )
}

export default PaymentPortal