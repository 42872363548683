import { useTheme } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { LogoHorizontal } from '../../assets/logos';
import { FacebookSVG, InstagramSVG, LinkedInSVG, XSVG } from '../../assets/social-icons';

const FooterNav = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        background:theme.palette.primary.dark,
        minHeight:240,
        alignItems:'center',
        justifyContent:'center'
      }}
    >
      <Grid container justifyContent="center">
        <Grid container width={{xs:'95%', sm:'33%'}}>
          <LogoHorizontal width={50} color1="#fff" style={{alignItems:'flex-start'}} />
          <Grid container gap={1}>
            <InstagramSVG width={24} color1="#fff" />
            <XSVG width={24} color1="#fff" />
            <FacebookSVG width={24} color1="#fff" />
            <LinkedInSVG width={24} color1="#fff" />
          </Grid>
        </Grid>
        <NavHeader
          title="Product"
          href="/product/"
        >
          <NavLink
            title="Core Features"
            href="/features/"
          />
          <NavLink
            title="Addons"
            href="/features/#addon"
          />
          <NavLink
            title="Pricing"
            href="/prices/"
          />
        </NavHeader>
        <NavHeader
          title="Terms"
          href="/terms/"
        >
          <NavLink
            title="Privacy Policy"
            href="/terms/privacy/"
          />
          <NavLink
            title="Terms of Service"
            href="/terms/service/"
          />
          <NavLink
            title="Cookies Policy"
            href="/terms/cookies/"
          />
        </NavHeader>
        <NavHeader
          title="Company"
          href="/company/"
        >
          <NavLink
            title="About"
            href="/about/"
          />
          <NavLink
            title="Newsletters"
            href="/newsletters/"
          />
          <NavLink
            title="Contact"
            href="/contact/"
          />
        </NavHeader>
      </Grid>
    </Grid>
  )
}

export default FooterNav

const NavLink = (props) => {
  const { title, href } = props;

  return (
    <a href={href} style={{textDecoration:'none', color:'inherit'}}>
      <Typography
        style={{
          color:'#fff'
        }}
      >
        {title}
      </Typography>
    </a>
  )
}

const NavHeader = (props) => {
  const { title, href, children } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      flexDirection="column"
      gap={1}
      width={{xs:'100%', sm:100, md:200}}
    >
      <a href={href} style={{textDecoration:'none', color:'inherit', margin:'0 0 8px'}}>
        <Typography
          style={{
            fontWeight:600,
            color:theme.palette.primary.light
          }}
        >
          {title}
        </Typography>
      </a>
      {children}
    </Grid>
  )
}