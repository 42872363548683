import React, { useState, useEffect } from 'react';
import { CameraSVG, CloseFilledSVG, FlipSVG } from '../../assets/icons';
import { Button, Collapse, Dialog, Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import FileDropZone from '../../components/ui/FileDropZone';
import { createStorageFile, deleteStorageFile, putTenantDataBag, putStorageFile, useGetTenantDataBag, useGetPublicFolder, getAllPublicStorageFileUploads } from '../../hooks/mutations';
import { ImageField, InputField } from '../../components/ui/Fields';
import { useApi } from '../../context/apiContext';
import { useTheme } from '@emotion/react';
import DialogUpload from '../ui/DialogUpload';
import axios from 'axios';

// Define the initial text styles
const TEXT_STYLES = ['normal', 'italic', 'oblique'];
const TEXT_ALIGN = ['left', 'center', 'right', 'justify'];
const TEXT_DECORATION = ['none', 'underline', 'line-through', 'overline'];
const FONT_WEIGHTS = ['normal', 'bold', 'bolder', 'lighter'];

const ElementEditorImage = ({ selectedElement, onTextStyleChange, onWrapStyleChange, onTextAltChange, onTextUrlChange }) => {
  // Combine all text-related styles into a single `style` object
  const [style, setStyle] = useState({
    objectFit: selectedElement?.style.objectFit || 'contain',
    width: selectedElement?.style.width,
    height: selectedElement?.style.height || '200px',
    color: selectedElement?.style.color || '#000000',
  });

  const [alt, setAlt] = useState(selectedElement.alt || '');
  const [url, setUrl] = useState(selectedElement.url || '');
  const [openGallery, setOpenGallery] = useState(false);
  const [width, setWidth] = useState(selectedElement?.style.width?.replace(/[^0-9]/g, '') || 100);
  const [widthFormat, setWidthFormat] = useState('%');
  const [height, setHeight] = useState(selectedElement?.style.height?.replace(/[^0-9]/g, '') || 'auto');
  const [heightFormat, setHeightFormat] = useState('px');
  const [galleryImages, setGalleryImages] = useState(null)
  const publicFolder = useGetPublicFolder({reload:null});

  // Whenever the selectedElement changes, update the local state
  useEffect(() => {
    if (selectedElement) {
      if (selectedElement.style) {
        setWidthFormat(selectedElement.style?.width?.replace(/[0-9]/g, ''));
        setWidth(selectedElement.style?.width?.replace(/[^0-9]/g, ''));
        setHeightFormat(selectedElement.style?.height?.replace(/[0-9]/g, ''));
        setHeight(selectedElement.style?.height?.replace(/[^0-9]/g, ''));
      };
      setStyle({
        fontFamily: selectedElement.style.fontFamily,
        fontSize: selectedElement.style.fontSize,
        fontWeight: selectedElement.style.fontWeight,
        color: selectedElement.style.color,
        textAlign: selectedElement.style.textAlign,
        textDecoration: selectedElement.style.textDecoration,
        fontStyle: selectedElement.style.fontStyle,
      });
    }
  }, [selectedElement]);

  // Update width when value changes
  useEffect(() => {
    if (!width) {
      setWidth('auto');
      const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
      onTextStyleChange({...reducedStyle.style, width: `auto` });
      return;
    }
    if (width && width !== 'auto' && widthFormat) {
      setStyle(prev => ({ ...prev, width: `${width}${widthFormat}` }))
      if (onTextStyleChange) {
        const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
        onTextStyleChange({...reducedStyle.style, width: `${width}${widthFormat}` });
      }
    }
  }, [width, widthFormat])

  // Update height when value changes
  useEffect(() => {
    if (!height) {
      setHeight('auto');
      const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
      onTextStyleChange({...reducedStyle.style, height: `auto` });
      return;
    }
    if (height && height !== 'auto' && heightFormat) {
      setStyle(prev => ({ ...prev, height: `${height}${heightFormat}` }))
      if (onTextStyleChange) {
        const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
        onTextStyleChange({...reducedStyle.style, height: `${height}${heightFormat}` });
      }
    }
  }, [height, heightFormat])

  // Update alt when value changes
  useEffect(() => {
    if (alt) {
      if (onTextAltChange) {
        onTextAltChange(alt);
      }
    }
  }, [alt])

  // Update url when value changes
  useEffect(() => {
    if (url) {
      if (onTextUrlChange) {
        onTextUrlChange(url);
      }
    }
  }, [url])


  // Handle change for the style fields
  const handleWrapStyleChange = (field, value) => {
    // Call the onWrapStyleChange function to update the element's wrapper style
    if (onWrapStyleChange) {
      const reducedWrapStyle = JSON.parse(JSON.stringify(selectedElement?.wrapStyle));
      console.log('reducedstyle', reducedWrapStyle)
      onWrapStyleChange({...reducedWrapStyle, [field]: value });
    }
  };

  // Handle change for the style fields
  const handleStyleChange = (field, value) => {
    const reducedStyle = JSON.parse(JSON.stringify(style));
    const updatedStyle = { ...reducedStyle, [field]: value };
    setStyle(updatedStyle);
    console.log('style', style)
    // Call the onTextStyleChange function to update the text element's style
    if (onTextStyleChange) {
      onTextStyleChange(updatedStyle);
    }

  };

  const handleOpenGallery = async () => {
    setOpenGallery(true);
    if (!galleryImages) {
      console.log('publicFolder.id', publicFolder.id)
      const gallery = await getAllPublicStorageFileUploads(publicFolder.id);
      if (gallery.status === "success") {
        setGalleryImages(gallery.data.results);
      }
    }
  }

  return (
    <div className="text-editor">
      <h4>Edit Text</h4>
      <div className="columns editor-field">
        <label>Alt Text</label>
        <input
          className="editor-field-input input-no-padding-block" 
          type="string"
          value={alt}
          onChange={(e) => setAlt(e.target.value)}
        />
      </div>

      <div className="columns editor-field">
        <label>Image URL</label>
        <input
          className="editor-field-input input-no-padding-block" 
          type="string"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>

      {/* <label>Image Gallery</label> */}
      <button
        className="editor-field-input" 
        type="string"
        onClick={handleOpenGallery}
      >
        Open Gallery
      </button>
      <h4>Edit Style</h4>



      <div className="editor-field">
        <div className="editor-field-key">
          <label>Object Fit</label>
        </div>
        <div className="editor-field-value">
          <select className="editor-field-input" value={style.objectFit} onChange={(e) => handleStyleChange('objectFit', e.target.value)}>
            <option value="cover">Cover</option>
            <option value="contain">Contain</option>
            <option value="fill">Fill</option>
            <option value="scale-down">Scale-down</option>
          </select>
        </div>
      </div>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Width</label>
        </div>
        <div className="editor-field-value-col-2">
          <input
            className="editor-field-input input-no-padding-block"
            type="number"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            min="20"
            max="1000"
          />
        </div>
        <div className="editor-field-value-col-3">
          <div
            className={`editor-field-value-col-3 item ${widthFormat === "px" ? 'selected' : ''}`}
            onClick={() => setWidthFormat('px')}
          >
            px
          </div>
          <div
            className={`editor-field-value-col-3 item ${widthFormat === "%" ? 'selected' : ''}`}
            onClick={() => setWidthFormat('%')}
          >
            %
          </div>
        </div>
      </div>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Height</label>
        </div>
        <div className="editor-field-value-col-2">
          <input
            className="editor-field-input input-no-padding-block"
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            min="20"
            max="1000"
          />
        </div>
        <div className="editor-field-value-col-3">
          <div
            className={`editor-field-value-col-3 item ${heightFormat === "px" ? 'selected' : ''}`}
            onClick={() => setHeightFormat('px')}
          >
            px
          </div>
          <div
            className={`editor-field-value-col-3 item ${heightFormat === "%" ? 'selected' : ''}`}
            onClick={() => setHeightFormat('%')}
          >
            %
          </div>
        </div>
      </div>

      <div className="editor-field">
        <div className="editor-field-key">
          <label>Alignment</label>
        </div>
        <div className="editor-field-value">
          <select className="editor-field-input" value={selectedElement.wrapStyle.textAlign || 'left'} onChange={(e) => handleWrapStyleChange('textAlign', e.target.value)}>
            <option key="left" value="left">Left</option>
            <option key="center" value="center">Center</option>
            <option key="right" value="right">Right</option>
          </select>
        </div>
      </div>

      <DialogUpload
        open={openGallery}
        onClose={() => setOpenGallery(false)}
        hideButtons
        header="Upload Image"
        // title="Create An Email Template"
        messages={{
          // one:'Are you sure you want to remove this field from your contact? It will be permanently removed once you submit changes to the contact.',
        }}
      >
        <GalleryDialog
          openGallery={openGallery}
          onClose={() => setOpenGallery(false)}
          url={url}
          setUrl={setUrl}
          galleryImages={galleryImages}
          publicFolder={publicFolder}
        />
      </DialogUpload>


      {/* <Dialog
        open={openGallery}
        onClose={() => setOpenGallery(false)}
      >
        <Grid
          container
        ></Grid>
      </Dialog> */}
    </div>
  );
};

export default ElementEditorImage;

const GalleryDialog = ({ openGallery, setOpenGallery, onClose, url, setUrl, publicFolder, galleryImages }) => {
  const live = true;
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [tab, setTab] = useState(0);
  const [generateImage, setGenerateImage] = useState(false);
  const [generatedImageUrl, setGeneratedImageUrl] = useState();
  const [genTerms, setGenTerms] = useState();


  const dataBag = useGetTenantDataBag();
  const theme = useTheme();
  const apiContext = useApi();
  const contextTenantDataBag = apiContext.contextTenantDataBag;
  const navBadge = contextTenantDataBag?.data?.navBadge;
  
  // Create a badge image file when it does not exist yet
  const uploadImage = async () => {
    console.log('uploading image')
    // Upload image to Public storage folder
    const storageFolderId = publicFolder.id;
    const fileName = file.file.name;
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('name', fileName);
    formData.append('folder', storageFolderId);
    console.log('formData', formData);

    const storageFileObj = {
      folderId: storageFolderId,
      storageFilePayload: formData
    }

    // Replace the file, keeping the same file id
    const storedImage = await createStorageFile(storageFileObj);
    console.log('storedImage', storedImage);

    // If image is stored successfully then save the image url to the data bag
    if (storedImage.status === "success") {
      // Add code to save the url
      setUrl(storedImage.data.file)
    }
 

  }

  // Replace a badge image with a new file
  const replaceImage = async () => {
    console.log('replacing image', file)
    // Upload image to storage
    const storageFolderId = publicFolder.id;
    const fileName = file.file.name;
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('name', fileName);
    formData.append('folder', storageFolderId);
    console.log('formData', formData);

    const storageFileObj = {
      folderId: storageFolderId,
      fileId: contextTenantDataBag.data.navBadge.storageId,
      storageFilePayload: formData
    }

    // Replace the file, keeping the same file id
    const storedImage = await putStorageFile(storageFileObj);
    console.log('storedImage', storedImage);

    // // If image is stored successfully then save the image url to the data bag
    if (storedImage.status === "success") {
      // Add code to save the badge image to it's location
      const dataObj = {
        id: dataBag.id,
        data: {
          data: {
            ...dataBag.data,
            navBadge: {
              url: storedImage.data.file,
              folderId: storedImage.data.folder,
              storageId: storedImage.data.id,
            }
          }
        }
      }
      const updateBadge = await putTenantDataBag(dataObj);
      console.log('updateBadge', updateBadge)
      if (updateBadge.status === 'success') {
        console.log(updateBadge.response);
        apiContext.handleSetContextTenantDataBag(updateBadge.data);
        handleClose();
      }

    }
 

  }

  // Replace a badge image that already exists
  const existingImage = async (img) => {
    console.log('existing image', img)

    // Add code to save the badge image to it's location
    const dataObj = {
      id: dataBag.id,
      data: {
        data: {
          ...dataBag.data,
          navBadge: {
            url: img.file,
            folderId: img.folder,
            storageId: img.id,
          }
        }
      }
    }
    const updateBadge = await putTenantDataBag(dataObj);
    console.log('updateBadge', updateBadge)
    if (updateBadge.status === 'success') {
      console.log(updateBadge.response);
      apiContext.handleSetContextTenantDataBag(updateBadge.data);
      handleClose();
    }

  }
 

  const handleClose = () => {
    setOpen(false);
    setHover(false);
    setFile(null);
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleGenerate = async () => {
    if (genTerms) {
      const headers = { 'Authorization': '9OsttIcG2TW1Abi8WxMSMct8m3jWPw6IrCH6uLUY44z9tYt5pfgkGRNI'}
      const imgUrl = await axios.get(`https://api.pexels.com/v1/search?query=${genTerms}&per_page=4`, headers)

      console.log('imgUrl', imgUrl);
      // .then( response => {
        // setGeneratedImageUrl(data.url);
      // });
    }
  }


  return (
    // <Dialog
    //   open={openGallery}
    //   onClose={onClose}
    //   fullWidth
    // >
      <Grid
        container
        style={{
          flexDirection:'column',
        }}
      >
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Upload" />
          <Tab label="Search" />
          <Tab label="Gallery" />
        </Tabs>

        <CustomTabPanel value={tab} index={0}>
          {
            file
              ?
                file.blob
                  ?
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'center',
                        minHeight:150,
                        border: '5px solid #ccc',
                      }}
                    >
                      <Button
                        onClick={() => setFile(null)}
                        style={{
                          columnGap:8,
                          marginBottom:8
                        }}
                      >
                        <FlipSVG width={15} color1={theme.palette.primary.main} />
                        Change
                      </Button>

                      <img
                        src={file.blob}
                        style={{
                          width:50
                        }}
                      />

                    </div>
                  :
                    null
              :
                  <Grid style={{textAlign:'center'}}>
                    <FileDropZone
                      setFile={setFile}
                      message="Replace current badge"
                    />
                  </Grid>
          }
        </CustomTabPanel>

        <CustomTabPanel value={tab} index={1}>
          <Grid
            container
            justifyContent="flex-end"
          >
            <InputField
              label="Image Generator Terms"
              placeholder="Separate terms with a comma (,)"
              fullWidth
              value={genTerms}
              onChange={e => setGenTerms(e.target.value)}
              margin
            />
            <Button variant="contained" sx={{my:1}} onClick={handleGenerate}>Generate Image</Button>
          </Grid>

        </CustomTabPanel>

        <CustomTabPanel value={tab} index={2}>
          {
            galleryImages
              ?
                galleryImages.length
                  ?
                    <Grid container>
                      {
                        galleryImages.map(img => (
                          <ImageGallery img={img} existingImage={existingImage} />
                        ))
                      }
                    </Grid>
                  :
                    <Grid container padding={3} height="100%" variant="center">
                      <Typography>No images have been uploaded yet.</Typography></Grid>
              :
                <Grid height="100%" variant="center"><Typography>Loading Gallery...</Typography></Grid>
          }
        </CustomTabPanel>

        {/* <Grid
          container
        >
          <Grid
            item
            style={{
              // padding:16,
              display:'flex',
              flexDirection:'column',
              alignItems:'center',
              justifyContent:'center',
            }}
            xs={12}
            sm={4}
          >
            {
              contextTenantDataBag?.data?.navBadge?.url
                ?
                  <>
                    <Button
                      onClick={deleteBadgeItem}
                      style={{
                        columnGap:8,
                        marginBottom:8
                      }}
                    >
                      <CloseFilledSVG width={15} color1={theme.palette.primary.main} />
                      Remove
                    </Button>

                    <img
                      src={contextTenantDataBag.data.navBadge.url}
                      style={{
                        width:50
                      }}
                    />
                  </>
                :
                  <img
                    src="https://static.fillmarketing.com/static/static/img/fill-logo_500px.png"
                    style={{
                      width:50
                    }}
                  />
            }
          </Grid>
          <Grid
            item
            style={{
              padding:16,
              minHeight:220
            }}
            xs={12}
            sm={8}
          >
            {
              file
                ?
                  file.blob
                    ?
                      <div
                        style={{
                          display:'flex',
                          flexDirection:'column',
                          alignItems:'center',
                          justifyContent:'center',
                          minHeight:150,
                          border: '5px solid #ccc',
                        }}
                      >
                        <Button
                          onClick={() => setFile(null)}
                          style={{
                            columnGap:8,
                            marginBottom:8
                          }}
                        >
                          <FlipSVG width={15} color1={theme.palette.primary.main} />
                          Change
                        </Button>

                        <img
                          src={file.blob}
                          style={{
                            width:50
                          }}
                        />

                      </div>
                    :
                      null
                :
                  !openGallery
                    ?
                      galleryImages
                        ?
                          <Grid
                            container
                          >
                            <Divider
                              style={{
                                width:'100%'
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize:'.85rem',
                                  fontWeight:500,
                                  color:"#aaa",
                                  display:'flex',
                                  alignItems:'center'
                                }}
                              >
                                GALLERY
                                <IconButton
                                  // onClick={() => setOpenGallery(false)}
                                >
                                  <CloseFilledSVG width={14} color1="#aaa" />
                                </IconButton>
                              </Typography>
                            </Divider>
                            {
                              galleryImages.length
                                ?
                                  <Grid container>
                                    {
                                      galleryImages.map(img => (
                                        <ImageGallery img={img} existingImage={existingImage} />
                                      ))
                                    }
                                  </Grid>
                                :
                                  <Grid container marginTop={6} height="100%" variant="center">
                                    <Typography>No images have been uploaded yet.</Typography></Grid>
                            }
                          </Grid>
                        :
                          <Grid height="100%" variant="center"><Typography>Loading Gallery...</Typography></Grid>

                    :
                      <Grid style={{textAlign:'center'}}>
                        <FileDropZone
                          setFile={setFile}
                          message="Replace current badge"
                        />
                        <Button
                          // onClick={handleOpenGallery}
                        >
                          Pick From Gallery
                        </Button>
                      </Grid>
            }

          </Grid>
        </Grid> */}

        <Grid
          container
          style={{
            justifyContent:'space-between',
            padding:'0 16px 8px'
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={uploadImage}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    // </Dialog>
  )
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        minHeight:150
      }}
      {...other}
    >
      {value === index && <Grid sx={{ p: 3 }}>{children}</Grid>}
    </div>
  );
}


const ImageGallery = (props) => {
  const { img, existingImage } = props;
  const [hover, setHover] = useState(false);
  return(
    <Grid
      item
      style={{
        padding:0
      }}
      xs
    >

      <img
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => existingImage(img)}
        src={img.file}
        style={{
          width:90,
          height:90,
          objectFit:'contain',
          borderRadius: 16,
          padding:8,
          border: `1px solid #ccc`,
          outline: hover ? '1px solid black' : '1px solid transparent',
          cursor:'pointer'
        }}
      />
    </Grid>
  )
}