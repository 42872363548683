import React, { useEffect, useState, cloneElement } from 'react'
import { Button, Collapse, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useGetEmailTemplates, useGetFilteredEmailTemplates } from '../../hooks/mutations';
import { AddSVG, EditSVG, VisibleSVG,DeleteSVG  } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import ReusableSearch from '../ui/ReusableSearch';
import { ButtonCustom } from '../ui/Buttons';
import { emailTemplateColumns } from '../../lib/Data';
import { Link } from 'react-router-dom';
import { deleteEmailTemplate } from '../../hooks/mutations';
import NewEmailTemplateForm from './NewEmailTemplateForm';
import ReusableModal from '../ui/ReusableModal';
import CustomTooltip from '../ui/CustomTooltip';
import DialogWarning from '../ui/DialogWarning';
import { DataGrid } from '@mui/x-data-grid';
import { mockData } from '../../lib/mockData';
import DialogCreate from '../ui/DialogCreate';
import Loading from '../ui/Loading';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';


const EmailTemplates = () => {
  const [reloadEmailTemplates, setReloadEmailTemplates] = useState(false);
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
  });
  const [pages, setPages] = useState({page: 1, count:null, next:null, prev:null});
  const incomingEmailTemplates = useGetFilteredEmailTemplates({reload:reloadEmailTemplates, filterBy:filterBy});
  const screenSize = useScreenSize();
  const [emailTemplates, setEmailTemplates] = useState(incomingEmailTemplates) // (mockData.templates);
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [selected, setSelected] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();
  const [snackMessage, setSnackMessage] = useState("");
  const displayColumns = [
    {
      field: 'name',
      label: 'Name',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/templates/${params.row.id}/`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'modified',
      label: 'Last Update',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (new Date(params.row.modified).toLocaleDateString())
    },
    {
      field: 'actions',
      label: '',
      width:100,
      // flex:1,
      editable: false,
      renderCell: (params) => (<EmailTemplateActions emailTemplate={params.row} setSnackMessage={setSnackMessage} setReloadEmailTemplates={setReloadEmailTemplates} />)
    }
  ]
  const dataColumns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/templates/${params.row.id}/`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'modified',
      headerName: 'Last Update',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (new Date(params.row.modified).toLocaleDateString())
    },
    {
      field: 'actions',
      headerName: '',
      width:100,
      // flex:1,
      editable: false,
      renderCell: (params) => (<EmailTemplateActions emailTemplate={params.row} setSnackMessage={setSnackMessage} setReloadEmailTemplates={setReloadEmailTemplates} />)
    }
  ]

  console.log('emailTemplates', emailTemplates)
  // Reset 'reloadEmailTemplates' state to false after it is triggered
  useEffect(() => {
    if (reloadEmailTemplates) {
      setReloadEmailTemplates(false);
    }
  }, [reloadEmailTemplates]);

  // Set emailTemplates state to incoming data on load
  useEffect(() => {
    if (incomingEmailTemplates) {
      setEmailTemplates(incomingEmailTemplates.results);
      const next = incomingEmailTemplates.next?.split('?page=')[1]?.split('&page')[0] || null;
      const prev = incomingEmailTemplates.previous?.split('?page=')[1]?.split('&page')[0] || null;
      const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1 );
      setPages({
        page: id,
        count: incomingEmailTemplates.count,
        next: next,
        prev: prev
      })

      setResetSearch(false);
    }
  }, [incomingEmailTemplates]);

  const handleDeleteClick = async (emailTemplateId) => {
    const deleted = await deleteEmailTemplate(emailTemplateId)
    if (deleted.status === 'success') {
      setReloadEmailTemplates(true); // <-- Trigger api call to get emailTemplates again
    } else {
      console.log('NOT DELETED!');
    }
  };

  const handleCloseCreateTemplate = () => {
    setOpenCreateTemplate(false);
  }

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => emailTemplates.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }
  

  return (
    <Grid
      container
      style={{
        // height:'calc(100vh - 148px)', // Add or subtract 55px if the Footer Paginate comopnent is used
        justifyContent: 'center',
      }}
    >
      <Grid
        style={{
          // background:'#fff',
          borderRadius: 8,
          // padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 2px 10px #00000020',
          width:'100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          // transition:'.4s',
          // maxWidth:1600
        }}
      >

        {/* <HeaderRow />
        <TableWrapper>
          {
            emailTemplates
              ?
                emailTemplates.map((emailTemplate, index) => (
                  <TableRow
                    emailTemplate={emailTemplate}
                    index={index}
                    key={emailTemplate.id}
                    onDeleteClick={() => handleDeleteClick(emailTemplate.id)}
                  />
                ))
              :
                <Typography>No custom fields have been created yet.</Typography>
          }
        </TableWrapper> */}



      </Grid>
        {
          !isSearching || emailTemplates.length
            ?
              // <DataGrid
              //   getRowId={(row) => row.id} 
              //   rows={emailTemplates?.length ? emailTemplates : []}
              //   columns={dataColumns}
              //   density="comfortable"
              //   disableVirtualization
              //   onRowSelectionModelChange={(ids) => {
              //     handleRowSelection(ids);
              //   }}
              //   initialState={{
              //     pagination: {
              //       paginationModel: {
              //         pageSize: 20,
              //       },
              //     },
              //   }}
              //   sx={{
              //     '&.MuiDataGrid-root':{
              //       borderBottom:'none',
              //       '& .MuiDataGrid-cell:focus': {
              //         outline: 'none',
              //         borderBottom:`2px solid ${theme.palette.primary.main}`,
              //         background:`${theme.palette.primary.light}15`
              //       }
              //     },
              //     ...fill_mui_scrollbar
              //   }}
              //   // pageSizeOptions={[5]}
              //   // autoPageSize
              //   checkboxSelection
              //   disableRowSelectionOnClick
              // />
              <FillTable
                title="Email Template List"
                rows={emailTemplates?.length ? emailTemplates : []}
                columns={displayColumns}
                // onRowClick={handleRowClick}
                setFilterBy={setFilterBy}
                pages={pages}
                selectedRows={selected}
                setSelectedRows={setSelected}
                handleRowSelection={handleRowSelection}
                actionCell={(row) => (
                  <EmailTemplateActions emailTemplate={row} setSnackMessage={setSnackMessage} setReloadEmailTemplates={setReloadEmailTemplates} />
                )}
                renderCell={(row, column) => (
                  <Stack direction="row">
                    <RenderCell row={row} column={column} />
                  </Stack>
                )}
                searchButton={
                  <ReusableFilterSearch
                    useSearch
                    isOpen
                    placeholder="Search Email Templates"
                    content={emailTemplates}
                    setContent={setEmailTemplates}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    searchFields={['name']}
                  />
                }
                toolbarButtons={[
                  <CustomTooltip
                    title="Create Email Template"
                  >
                    <IconButton
                      href="/templates/new"
                      // onClick={() => setOpenCreateTemplate(true)}
                      text={screenSize.sm ? null : "New Email Template"}
                    >
                      <AddSVG width={18} />
                    </IconButton>
                  </CustomTooltip>
                ]}
                // toolbarActions={
                //   <BulkActions
                //     selected={selected}
                //     setReloadSurveys={setReloadSurveys}
                //   />
                // }
                checkboxes={false}
                sx={{
                  ...fill_mui_scrollbar
                }}
              />

            :
              <Grid
                container
                style={{
                  justifyContent:'center',
                }}
              >
                <Typography>No Forms Found.</Typography>
              </Grid>
        }
      <div id='gjs' style={{display:'none'}}></div>

      <DialogCreate
        open={openCreateTemplate}
        onClose={handleCloseCreateTemplate}
        hideButtons
        header="Create Template"
        // title="Create An Email Template"
        messages={{
          // one:'Are you sure you want to remove this field from your contact? It will be permanently removed once you submit changes to the contact.',
        }}
      >
        <NewEmailTemplateForm handleCloseCreateTemplate={handleCloseCreateTemplate} setReloadEmailTemplates={setReloadEmailTemplates} />
      </DialogCreate>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open: false }))}
        notice={snackMessage}
      />
    </Grid>
  )
}

export default EmailTemplates

const HeaderRow = (props) => {
  const { align } = props;
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        background: theme.palette.primary.main,
        padding:'12px 32px',
        marginTop:16,
        // borderRadius:'4px 4px 0 0'
      }}
    >
      {
        emailTemplateColumns.map(item => (
          <TableColumn item={item}>
            <Typography style={{color:'#fff'}}>{item.label}</Typography>
          </TableColumn>
        ))
      }
    </Grid>
  )
}

const TableWrapper = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        maxHeight:200
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { emailTemplate, index, onDeleteClick, setSnackMessage } = props;

  return (
    <Grid
      container
      style={{
        background: index % 2 ? '#ffffff' : '#fdfdfd',
        padding:'12px 32px',
      }}
    >
      {
        emailTemplateColumns.map(item => (
          <TableColumn item={item}>
            {
              // item.id === 'data_type' &&
              //   <Grid
              //     style={{
              //       width:50,
              //       display:'flex',
              //       alignItems:'center',
              //       justifyContent:'center',
              //       marginLeft:-10
              //     }}
              //   >
              //     {cloneElement(selectedIcons[emailTemplate.data_type], {color1:'#777'})}
              //   </Grid>
            }
            {
              item.id === 'actions' &&
                <div
                  style={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'flex-end',
                    width:'100%'
                  }}
                >
                  {/* <Link
                    to={`/emailTemplates-take/${emailTemplate.id}`}
                    style={{
                      textDecoration:'none',
                      color:'inherit'
                    }}
                  >
                    <Grid
                      style={{
                        padding:4,
                        margin:'0 4px',
                        cursor:'pointer'
                      }}
                    >
                      <VisibleSVG width={20} color1="#777" />
                    </Grid>
                  </Link> */}
                  <a
                    href={`/templates/${emailTemplate.id}/`}
                    style={{
                      textDecoration:'none',
                      color:'inherit'
                    }}
                  >
                    <Grid
                      style={{
                        padding:4,
                        margin:'0 4px',
                        cursor:'pointer'
                      }}
                    >
                      <EditSVG width={17} color1="#777" />
                    </Grid>
                  </a>

                  <Grid
                      style={{
                        padding:4,
                        margin:'0 4px',
                        cursor:'pointer'
                      }}
                      onClick={onDeleteClick}
                  >
                    <DeleteSVG width={13} color1="#777" />
                  </Grid>
                  
                </div>
            }
            <Typography style={{ fontSize:'1rem'}}>{emailTemplate[item.id]}</Typography>
          </TableColumn>
        ))
      }
     
    </Grid>
  )
}

const TableColumn = (props) => {
  const { children, item } = props;
  return (
    <Grid
      item
      sx={{
        justifyContent: item.align ? item.align : 'flex-start',
        display:'flex',
        alignItems:'center'
      }}
      xs={item.width ? (item.width) : true}
    >
      {children}
    </Grid>
  )
}

const EmailTemplateActions = (props) => {
  const { emailTemplate, setSnackMessage, setReloadEmailTemplates } = props;
  // const emailTemplate = params.row;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }


  const deleteTemplate = async () => {
    setDeleting(true);
    const deletedTemplate = await deleteEmailTemplate(emailTemplate.id)
    if (deletedTemplate.status === 'success') {
      setReloadEmailTemplates(true); // <-- Reload the current forms to show the deletion
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
      setSnackMessage({ open: true, message: 'Template deleted', severity: 'success' })
    }
  }


  return (
    <Stack
      direction="row"
      spacing={.5}
    >
      <CustomTooltip
        title="Edit Template"
      >
        <IconButton
          href={`/templates/${emailTemplate.id}/`}
        >
          <EditSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip
        title="Delete Template"
      >
        <IconButton
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete Email Template"
        title={`You are about to delete the ${emailTemplate.name} email template`}
        messages={{
          one:'Are you sure you want to delete this template? It will be permanently deleted.',
          confirm:'Yes'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={deleteTemplate}
        icons

      />

    </Stack>

  )

}

const RenderCell = ({ row, column }) => {

  switch (column.field) {
    case 'name':
      return (
        <a
          href={`/templates/${row.id}/`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {row.name}
        </a>
      )
    case 'modified':
      return (
        new Date(row.modified).toLocaleDateString()
      )
    default:
      break;
  }
}
